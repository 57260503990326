export const proCateCols = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 200
  },
  {
    prop: 'productCategory.name',
    label: '原型分类'
  }
]
