<template>
  <section>
    <BaseProductPage
      :cols="cols"
      :formField="isMain ? formField : baseInfoChildField"
      :resetUrl="url"
      :isEdit="true"
      :isEditStruct="isEditStruct"
      :isSearch="isSearch"
      :referSizeSpanCol="['参考尺码', '尺码']"
      :childrenList="childrenList"
    >
      <template v-slot:top>
        <div class="page-title-tip">
          <i class="el-icon-delete"></i>
          已上架的尺码或款式无法进行修改！
        </div>
        <BatchOperation class="batch-operation-wrapper" style="margin-top: -10px">
          <div class="g-left-operation">
            <el-button
              v-p="['externaladmin:product:maintain:basicsMange:add']"
              type="primary"
              size="small"
              @click="goInfoPage('add')"
              class="el-icon-plus"
            >
              新增原型基础信息
            </el-button>
            <el-button
              size="small"
              plain
              @click="childAccount"
              class="childBtn"
              v-p="['product:maintain:basicsMange:module:childAccount']"
            >
              子账号数据
            </el-button>
          </div>
        </BatchOperation>
      </template>
      <template v-slot:batchOperation="{ sup_this }">
        <BatchOperation style="margin-top: 0">
          <div>
            <UpdateCate
              v-p="['externaladmin:product:maintain:basicsMange:updateCate']"
              :sup_this="sup_this"
              :data="sup_this.selectedData || []"
              style="margin-right: 10px"
            />
            <assignData
              :data="sup_this.selectedData || []"
              :sup_this="sup_this"
              v-p="['externaladmin:product:maintain:basicsMange:assignData']"
              @getChildList="getChildList"
            ></assignData>
          </div>
        </BatchOperation>
      </template>
      <template v-slot="{ sup_this }">
        <el-table-column fixed="right" style="z-index: 100" align="center" label="操作" width="180px">
          <template slot-scope="{ row }">
            <el-button
              v-p="['externaladmin:product:maintain:basicsMange:edit']"
              type="text"
              size="mini"
              class="line inline-block"
              @click="goInfoPage('edit', row)"
            >
              编辑
            </el-button>
            <del-btn
              v-if="showDel(row)"
              v-p="['externaladmin:product:maintain:basicsMange:delete']"
              :id="row.id"
              :sup_this="sup_this"
              type="prim_prod_info"
            ></del-btn>
          </template>
        </el-table-column>
      </template>
    </BaseProductPage>
  </section>
</template>

<script>
import UpdateCate from '@/components/updateCate'

import BaseProductPage from '../../baseProductPage'

import Edit from '../../module/edit'
import delBtn from '@/views/components/delBtn'
import assignData from './module/assignData'
import { baseInfoField as formField } from '../../field'
import { basicCols as cols } from '../../cols'
import { mapGetters } from 'vuex'
import { validateLowerCase } from '@/utils'
import { baseInfoChildField } from '@/views/product/field/productFields'
export default {
  name: 'basicsMange',
  components: {
    UpdateCate,
    BaseProductPage,
    Edit,
    delBtn,
    assignData
  },
  data() {
    return {
      baseInfoChildField,
      cols,
      formField,
      url: '/externaladmin/prototypeService/productPrototype/list',
      isSearch: this.checkPermission(['externaladmin:product:maintain:basicsMange:search']),
      isEditStruct: this.checkPermission(['externaladmin:product:maintain:basicsMange:editStruct']),
      childrenList: []
    }
  },
  methods: {
    goInfoPage(type, row) {
      this.$store.commit('SET_BUS', {
        basicsEdit: row
      })
      if (row) {
        return this.$router.push(`/product/maintain/basicsEdit?type=${type}&status=${row.status}`)
      }
      this.$router.push(`/product/maintain/basicsEdit?type=${type}`)
    },
    childAccount() {
      this.$router.push({
        path: '/product/maintain/childAccount'
      })
    },
    getChildList(val) {
      if (this.isMain) {
        this.childrenList = val
      }
    }
  },
  computed: {
    ...mapGetters(['isMain']),
    showDel() {
      return (data) => {
        const validateStyle = $GET(data, 'styleList', []).find(({ status }) => status === 1)
        const validateSize =$GET(data, 'sizeList', []).find(({ status }) => status === 1)
        const validate = validateStyle || validateSize
        return !validate
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.childBtn {
  color: $color-primary;
  border: 1px solid $color-primary;
}
</style>
